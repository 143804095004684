import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { NgHttpCachingService } from 'ng-http-caching';
import { Observable, map, switchMap, of } from 'rxjs';
import { BaseModel } from 'src/app/models/utils';
import { environment } from 'src/environments/environment';

export type CompanyFeatureType = 'AWK' | 'DWRM' | 'MATCHMAKING';

export interface CompanyFeatureDefinition {
  label: string;
  type: CompanyFeatureType;
}

export interface CompanyFeature {
  type: CompanyFeatureType;
  activeSince?: Date | string;
  activeUntil?: Date | string;
}

export interface CompanyFeatures extends Partial<BaseModel> {
  companyId: string;
  features: CompanyFeature[];
}

@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  private http = inject(HttpClient);
  private header = new HttpHeaders({
    'Content-Type': 'application/json',
    'X-NG-HTTP-CACHING-ALLOW-CACHE': 'true',
  });
  private ngHttpCachingService = inject(NgHttpCachingService);

  public getFeatureList(): Observable<CompanyFeatureDefinition[]> {
    return this.http.get<CompanyFeatureDefinition[]>(
      environment.matchMakingApiUrl + '/v1/features/list'
    );
  }

  public getFeaturesForCompany(companyId: string) {
    return this.http.get<CompanyFeatures>(
      environment.matchMakingApiUrl + `/v1/features/company/${companyId}`,
      { headers: this.header }
    );
  }

  public postFeatures(companyId: string, features: CompanyFeatureType[]) {
    this.ngHttpCachingService.clearCacheByRegex(/features.*company/);
    return this.http.post<CompanyFeatures>(
      environment.matchMakingApiUrl + `/v1/features/company/${companyId}`,
      {
        companyId,
        features: features.map((type) => ({
          type,
        })),
      } as CompanyFeatures,
      { headers: this.header }
    );
  }

  public putFeatures(
    companyId: string,
    features: CompanyFeatureType[],
    id: string
  ) {
    this.ngHttpCachingService.clearCacheByRegex(/features.*company/);
    return this.http.put<CompanyFeatures>(
      environment.matchMakingApiUrl + `/v1/features/company/${companyId}`,
      { id, features: features.map((type) => ({ type })) } as CompanyFeatures,
      { headers: this.header }
    );
  }

  public saveFeatures(companyId: string, features: CompanyFeatureType[]) {
    return this.getFeaturesForCompany(companyId).pipe(
      switchMap((companyFeatures) => {
        if (companyFeatures?.features)
          return this.putFeatures(companyId, features, companyFeatures.id);

        return this.postFeatures(companyId, features);
      })
    );
  }

  public companyHasFeature(companyId: string, type: CompanyFeatureType) {
    return this.getFeaturesForCompany(companyId).pipe(
      map((features) =>
        features.features.some((feature) => feature.type === type)
      )
    );
  }

  public activateFeature(companyId: string, type: CompanyFeatureType) {
    return this.getFeaturesForCompany(companyId).pipe(
      switchMap((companyFeatures) => {
        if (!companyFeatures) return this.saveFeatures(companyId, [type]);

        if (companyFeatures.features.some((feature) => feature.type === type))
          return of(companyFeatures);

        return this.saveFeatures(companyId, [
          ...companyFeatures.features.map((feature) => feature.type),
          type,
        ]);
      })
    );
  }

  public deactivateFeature(companyId: string, type: CompanyFeatureType) {
    return this.getFeaturesForCompany(companyId).pipe(
      switchMap((companyFeatures) =>
        this.saveFeatures(
          companyId,
          companyFeatures.features
            .filter((feature) => feature.type !== type)
            .map((feature) => feature.type)
        )
      )
    );
  }
}
